<template>
  <div class="box">
    <div class="search">
      <el-form label-width="120px" label-height="60px">
        <el-row>
          <el-col :span="7">
            <el-form-item label="用户昵称：" prop="contactName">
              <el-input v-model="queryInfo.condition.userNickName" placeholder="请输入" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="用户手机号：" prop="contactName">
              <el-input v-model="queryInfo.condition.userPhone" placeholder="请输入" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="兑换卡号：" prop="contactName">
              <el-input v-model="queryInfo.condition.cardNo" placeholder="请输入" clearable></el-input>
            </el-form-item>
          </el-col>

        </el-row>
        <el-row>

          <el-col :span="7">
            <el-form-item label="兑换时间：" prop="contactName">
              <el-date-picker @change="change" v-model="valuechange" type="daterange" start-placeholder="开始日期"
                end-placeholder="结束日期" />
            </el-form-item>
          </el-col>
          <el-col :span="7" style="display: flex; justify-content: flex-end">
            <el-button @click="reset" icon="el-icon-refresh-left">重置</el-button>
            <el-button type="primary" @click="search" icon="el-icon-search">搜索</el-button>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="table">
      <div class="add">
        <div style="display:flex;font-size: 12px;color: #409eff; align-items: center;">
          <div style="margin-right: 20px;">兑换金额 ({{ this.price1 }})</div>
          <div>赠送金额 ({{ this.price2 }})</div>
        </div>
        <el-button type="primary" icon="el-icon-circle-plus-outline" @click="exchange">人工兑换</el-button>
      </div>

      <el-table @selection-change="handleSelectionChange" :data="tableData" style="width: 100%"
        height="calc(100vh - 380px)" border :summary-method="summaryTotal" :show-summary="true" :header-cell-style="{
          'background-color': '#f2f2f2',
          'text-align': 'center',
          border: '1px solid #ddd',
        }">
        <!-- <el-table-column type="selection" width="50"> </el-table-column> -->
        
        <el-table-column width="170" prop="userCode" label="用户id" align="center">
        </el-table-column>
        <el-table-column width="170" prop="userNickName" label="用户昵称" align="center">
        </el-table-column>
        <el-table-column prop="userPhone" label="手机号" width="100" align="center">
        </el-table-column>
        <el-table-column prop="cardNo" width="150" label="卡号" align="center">
        </el-table-column>
        <el-table-column prop="faceValue" width="150" label="兑换金额" align="center">
        </el-table-column>
        <el-table-column prop="giveValue" label="赠送金额" align="center">
        </el-table-column>
        <el-table-column prop="balance" width="150" label="账户余额" align="center">
        </el-table-column>
        <el-table-column prop="creatTime" width="150" label="兑换时间" align="center">
        </el-table-column>
        <el-table-column prop="type" width="150" label="兑换方式" align="center">
        </el-table-column>
        <el-table-column prop="auditStatus" width="150" label="审核状态" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.auditStatus == 1 ? '审核中' : scope.row.auditStatus == 2 ? '审核通过' : '审核不通过' }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="creatName" label="申请人" width="150" align="center">
        </el-table-column>
        <el-table-column prop="auditName" label="审核人" width="150" align="center">
        </el-table-column>
        <el-table-column prop="auditMessage" label="审核意见" width="150" align="center">
        </el-table-column>
      </el-table>
    </div>
    <div class="block">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="queryInfo.currPage" :page-sizes="[10]" :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </div>
    <el-dialog title="人工兑换" :visible.sync="showDialog" width="420px" height="300px">
      <div class="dia">
        <div class="dia-tap">
          <div :class="tapshow == i ? 'active' : ''" @click="tap(i)" v-for="(item, i) in taplist" :key="i">{{ item }}</div>
        </div>
        <div v-show="tapshow == 0">
          <el-form label-width="120px" label-position="left">
            <el-row>
              <el-col span="18">
                <el-form-item label="用户手机号">
                  <el-input v-model="fo.userPhone" type="text" placeholder="请输入"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col span="18">
                <el-form-item label="兑换金额">
                  <el-input v-model.number="fo.faceValue" type="text" placeholder="请输入"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col span="18">
                <el-form-item label="赠送金额">
                  <el-input v-model.number="fo.giveValue" type="text" placeholder="请输入"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <div v-show="tapshow == 1">
          <el-form label-width="120px" label-position="left">
            <el-row>
              <el-col span="18">
                <el-form-item label="用户手机号">
                  <el-input v-model="fo.userPhone" type="text" placeholder="请输入"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <!-- <el-row>
            <el-col span="18">
              <el-form-item label="兑换金额">
                <el-input v-model="fo.faceValue" type="text" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
                  </el-row>
                  <el-row>
            <el-col span="18">
              <el-form-item label="赠送金额">
                <el-input v-model="fo.giveValue" type="text" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
                  </el-row> -->
            <el-row>
              <el-col span="18">
                <el-form-item label="储值卡账号">
                  <el-input v-model="fo.cardNo" type="text" placeholder="请输入"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col span="18">
                <el-form-item label="储值卡密码">
                  <el-input v-model="fo.cardPassword" type="text" placeholder="请输入"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>

        <div class="but" style="margin-left: 90px;margin-top: 50px;">
          <el-button size="medium" style="margin-right: 40px;" @click="showDialog = false">取消 </el-button>
          <el-button size="medium" type="primary" @click="bc()">保存 </el-button>
        </div>


      </div>
    </el-dialog>



  </div>
</template>

<script>
import tableOne from "./tablethree/index.vue";
export default {
  components: {
    tableOne,
  },
  data() {
    return {

      taplist: ["无卡兑换", "储值卡兑换"],
      tapshow: 0,
      fo: {

      },
      total: 1,
      showDialog: false,
      queryInfo: {
        condition: {
          userNickName: "",
          userPhone: "",
          cardNo: ""
        },
        currPage: 1,
        pageCount: 1,
        pageSize: 10

      },
      tableData: [
        {
          name: 'wdz',
          type: 1
        }
      ],
      tableHeader: [
        {
          name: "用户昵称",
          prop: "userNickName",
          minWidth: "100px"
        },
        {
          name: "手机号",
          prop: "userPhone",
          minWidth: "100px"
        },
        {
          name: "兑换金额",
          prop: "faceValue",
          minWidth: "100px"
        },
        {
          name: "赠送金额",
          prop: "giveValue",
          minWidth: "100px"
        },
        {
          name: "账户余额",
          prop: "ngiveValue",
          minWidth: "100px"
        },
        {
          name: "兑换时间",
          prop: "creatTime",
          minWidth: "200px"
        },

        {
          name: "兑换方式",
          prop: "type",
          minWidth: "100px"
        },
        {
          name: "审核状态",
          prop: "auditStatus",
          minWidth: "100px"
        },
        {
          name: "申请人",
          prop: "creatName",
          minWidth: "100px"
        },
        {
          name: "审核人",
          prop: "auditName",
          minWidth: "100px"
        },
        {
          name: "审核意见",
          prop: "auditMessage",
          minWidth: "100px"
        },

      ],
      headerHeight: 0,
      height: window.innerHeight - 380, //表格高度
      loading: false,
      rightdata: [1, 2],
      transactionAmountSum: 0,
      handlingFeesSum: 0,
      settlementAmountSum: 0,
      price1: '',
      price2: '',
      price3: '',
      valuechange: ''
    };
  },
  mounted() {
    this.getList();
    this.getprice()

  },
  methods: {
    getprice() {
      var that = this;
      that.$http.post("/audit/dataStatistics", that.queryInfo.condition).then(function (response) {
        console.log(response.data.data);
        if (response.data.code == 200) {
          console.log(response.data.data, 'response.data.data');

          that.price1 = response.data.data.totalValue
          that.price2 = response.data.data.totalGiveValue
          that.price3 = response.data.data.totalBalance
        }
      });

    },
    //自定义表格合计行
    summaryTotal(param) {
      let sums = []
      sums[0] = <div>总合计</div>

      sums[3] = <div>{this.price1}</div>
      sums[4] = <div>{this.price2}</div>
      sums[5] = <div>{this.price3}</div>
      return sums
    },
    tap(i) {
      this.fo = {}
      this.tapshow = i;
    },
    added() {
      this.$router.push({ path: '/auditdetail' })
    },
    sh(name) {
      console.log(name);
      this.showDialog = name
    },
    //获取数据
    getList() {
      var that = this;
      that.$http.post("/audit/list", that.queryInfo).then(function (response) {
        console.log(response.data.data);
        if (response.data.code == 200) {
          that.total = response.data.data.count;
          that.queryInfo.currPage = response.data.data.currPage
          that.tableData = response.data.data.data;
        }
      });
    },
    exchange() {
      this.showDialog = true

    },
    bc() {
      const userInfo = JSON.parse(localStorage.getItem('userInfo')).userInfo
      this.fo.creatName = userInfo.nickName
      var that = this;
      if (that.tapshow == 0) {
        that.fo.type = "无卡兑换"
        that.$http.post("/audit/add", that.fo).then(function (response) {
          console.log(response.data.data);
          if (response.data.code == 200) {
            that.getList()
            that.showDialog = false
            that.$message.success('提交成功');



          } else {
            that.$message.error(response.data.message);
          }
        });
      } else {
        that.fo.type = "储值卡兑换"
        that.$http.post("/audit/add", that.fo).then(function (response) {
          console.log(response.data.data);
          if (response.data.code == 200) {
            that.getList()
            that.showDialog = false
            that.$message.success('提交成功');
          } else {
            that.$message.error(response.data.message);
          }
        });
      }
    },
    // 修改页数大小
    handleSizeChange(val) {
      this.queryInfo.pageSize = val;
      this.getList()
    },

    // 获取当前页面
    handleCurrentChange(val) {
      this.queryInfo.currPage = val;
      this.getList()
    },
    search() {
      this.getList()
      this.getprice()
    },
    reset() {
      this.queryInfo.condition = {

      }
      this.queryInfo.currPage = 1;
      this.getList()
    },
    change() {
      console.log(this.valuechange);
      if (this.valuechange) {
        this.queryInfo.condition.startTime = this.newDate(this.valuechange[0])
        this.queryInfo.condition.endTime = this.newDate(this.valuechange[1])
      } else {
        this.queryInfo.condition = {

        }

      }
    },
    newDate(time) {
      var date = new Date(time)
      var y = date.getFullYear()
      var m = date.getMonth() + 1
      m = m < 10 ? '0' + m : m
      var d = date.getDate()
      d = d < 10 ? '0' + d : d
      var h = date.getHours()
      h = h < 10 ? '0' + h : h
      var minute = date.getMinutes()
      minute = minute < 10 ? '0' + minute : minute
      var s = date.getSeconds()
      s = s < 10 ? '0' + s : s
      return y + '-' + m + '-' + d + ' ' + h + ':' + minute + ':' + s
    },
  }
};
</script>

<style lang="less" scoped>
.box {

  .search {
    // height: 70px;
    background-color: #fff;
    box-sizing: border-box;
    padding-top: 20px;
    box-sizing: border-box;

  }

  .table {
    background-color: #fff;
    margin-top: 20px;
    padding: 20px;

    .add {
      display: flex;
      justify-content: space-between;

    }

  }

  .dia {
    box-sizing: border-box;
    width: 400px;
    height: 400px;

    .dia-tap {
      display: flex;
      background-color: rgba(223, 243, 255, 0.44);
      height: 40px;
      margin-bottom: 20px;

      div {
        flex: 1;
        text-align: center;
        line-height: 40px;
        cursor: pointer;
      }

      .active {
        font-weight: 700;
        background-color: rgba(223, 243, 255, 0.6);
      }
    }

    .diafour {
      text-align: center;
    }
  }

  /deep/ .el-step__icon.is-text {
    background-color: #02a7f0;
    color: #fff;
    border: 2px solid #02a7f0;
  }
}
</style>