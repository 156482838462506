<template>
  <div class="renderTable">
    <el-table
      ref="table"
      :data="tableData"
      :height="height"
      style="width: 100%"
      v-loading="loading"
      border
    >
      <el-table-column
        label="序号"
        type="index"
        width="50"
        align="center"
      ></el-table-column>
      <template v-for="item in tableHeader">
        <el-table-column
          :label="item.name"
          :prop="item.prop"
          :min-width="item.minWidth"
          align="center"
        >
        <template #default="scope">
        <el-button
          v-if="item.prop === 'type'"
          type="text"
        >
          {{ scope.row[item.prop] === '1' ? '人工充值' : '人工充值' }}
        </el-button>
        <el-button
          v-if="item.prop === 'auditStatus'"
          type="text"
        >
        {{
          scope.row[item.prop] == '1'
            ? '审核中'
            : scope.row[item.prop] == '2'
            ? '审核通过'
            : '审核不通过'
        }}
        </el-button>
      </template>
        
        </el-table-column>
      </template>
     
    </el-table>
  </div>
</template>

<script>

export default {
  props: {
    tableHeader: {
      type: Array,
      default: []
    },
    tableData: {
      type: Array,
      default: []
    },
    height: {
      type: Number,
      default: 0
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    show(row) {
      this.$emit('show', 'true');
    },
    async handleDelte(merchantCode) {
      const res = await this.$http.post("/merchant/deleteMerchant", {
        merchantCode
      });
      if (res.data.code === 200) {
        this.$message.success("删除成功");
        eventBus.emit("delSuccess");
      } else {
        this.$message.error(res.data.message);
      }
    }
  }
};
</script>

<style lang="less" scoped>
/deep/ .el-button {
  padding: 0 8px;
}
</style>
