import { render, staticRenderFns } from "./change.vue?vue&type=template&id=61dca262&scoped=true"
import script from "./change.vue?vue&type=script&lang=js"
export * from "./change.vue?vue&type=script&lang=js"
import style0 from "./change.vue?vue&type=style&index=0&id=61dca262&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61dca262",
  null
  
)

component.options.__file = "change.vue"
export default component.exports